/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ready-for-battle': {
    width: 28,
    height: 16,
    viewBox: '0 0 28 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.78 3.076l.442.009-.135.14a.375.375 0 00.009.517l.312.322c-.714.404-2.023.86-3.9.387-2.989-.753-.962 2.943 1.15 5.128 2.114 2.185 6.003 4.454 5.214 1.453-.502-1.907-.112-3.364.238-4.173l1.11 1.147c.14.145.364.15.5.01l.049-.051 2.929 3.028-3.431 3.549a.375.375 0 00-.01.517l.811.838c.136.14.36.137.5-.009L14 12.34l3.431 3.548c.141.146.365.15.5.01l.811-.839a.375.375 0 00-.009-.517l-3.431-3.549 2.929-3.028.05.05c.135.14.359.136.5-.009L19.89 6.86c.35.809.74 2.266.238 4.173-.79 3.001 3.1.732 5.213-1.453s4.14-5.881 1.151-5.128c-1.877.473-3.186.017-3.9-.387l.312-.322a.375.375 0 00.01-.518l-.136-.14.442-.008a.74.74 0 00.667-.477l.631-1.612C24.754.385 24.19-.19 23.61.06l-1.537.668a.765.765 0 00-.454.686l-.008.464-.098-.102a.346.346 0 00-.5.01l-.704.728a.676.676 0 00-.193-.156l-2.21-1.188c-.62-.333-1.306.395-.965 1.025l1.198 2.222a.69.69 0 00.148.188L16.889 6.05a.375.375 0 00-.01.517l.05.051-2.93 3.03-2.928-3.03.049-.05a.375.375 0 00-.009-.518L9.713 4.605a.69.69 0 00.148-.188l1.198-2.222c.34-.63-.345-1.358-.964-1.025l-2.21 1.188a.675.675 0 00-.194.156l-.704-.728a.346.346 0 00-.5-.01l-.098.102-.008-.464a.765.765 0 00-.454-.686L4.39.06c-.58-.251-1.144.325-.908.927l.63 1.612a.74.74 0 00.668.477z" _fill="#fff"/>'
  }
})
