/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'eye_open': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<mask id="svgicon_eye_open_a" maskUnits="userSpaceOnUse" x="0" y="2" width="18" height="13"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.981 2.7C5.47 2.7 2.383 4.652.507 7.612a1.97 1.97 0 000 2.112c1.876 2.96 4.962 4.91 8.474 4.91 3.513 0 6.599-1.95 8.475-4.91a1.97 1.97 0 000-2.112C15.58 4.652 12.494 2.7 8.98 2.7zm-.365 7.15a1.24 1.24 0 01-.37-.177 1.184 1.184 0 01-.512-.972c0-.655.548-1.201 1.241-1.201.693 0 1.242.546 1.242 1.201 0 .656-.548 1.2-1.242 1.2a1.28 1.28 0 01-.36-.05zm.365 2.768c-2.637 0-5.15-1.481-6.722-3.95C3.83 6.199 6.344 4.717 8.98 4.717c2.637 0 5.15 1.482 6.723 3.951-1.572 2.469-4.086 3.95-6.723 3.95zM8.975 5.7C7.284 5.7 5.9 7.036 5.9 8.701c0 1.665 1.384 3 3.075 3 1.69 0 3.075-1.335 3.075-3s-1.384-3-3.075-3zm-.9 4.219a1.537 1.537 0 00.46.22l-.059-.019a1.554 1.554 0 01-.401-.201z" _fill="#07F"/></mask><g mask="url(#svgicon_eye_open_a)"><path pid="1" _fill="#fff" d="M0 0h18v18H0z"/></g>'
  }
})
