/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'chat-2': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.724 3H5.276a2.21 2.21 0 00-1.61.713A2.516 2.516 0 003 5.431v8.488a1.11 1.11 0 00.168.6c.112.178.271.315.457.394a.967.967 0 00.39.087.942.942 0 00.383-.083.994.994 0 00.324-.235l1.584-1.691h6.418a2.21 2.21 0 001.608-.713A2.516 2.516 0 0015 10.563V5.429c0-.644-.24-1.261-.667-1.716A2.21 2.21 0 0012.723 3zM6 6.75A.75.75 0 016.75 6h4.5a.75.75 0 010 1.5h-4.5A.75.75 0 016 6.75zM6.75 9a.75.75 0 000 1.5h4.5a.75.75 0 000-1.5h-4.5z" _fill="#9FB0C9"/>'
  }
})
