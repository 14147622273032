/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'picture': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M0 1.593C0 .713.712 0 1.593 0h10.814C13.287 0 14 .712 14 1.593v10.814c0 .88-.712 1.593-1.593 1.593H1.593C.713 14 0 13.288 0 12.407V1.593zm12.727 6.416V1.593a.32.32 0 00-.32-.32H1.593a.32.32 0 00-.32.32v7.689l1.459-1.46a.636.636 0 01.832-.058l2.068 1.551 3.43-4.002a.636.636 0 01.933-.036l2.732 2.732zm-11.454 4.4c0 .176.142.318.32.318h10.814c.178 0 .32-.142.32-.317V9.809L9.581 6.663l-3.37 3.933a.636.636 0 01-.866.095L3.242 9.113l-1.97 1.969v1.327zm3.182-6.682a1.273 1.273 0 110-2.545 1.273 1.273 0 010 2.545z" _fill="#fff"/>'
  }
})
