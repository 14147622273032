/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'done': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.699 3.978a.667.667 0 01-.01.943l-7.725 7.556a.667.667 0 01-.923.008l-4.72-4.444a.667.667 0 01.914-.97l4.254 4.005 7.267-7.108a.667.667 0 01.943.01z" _fill="#000"/>'
  }
})
