/* eslint-disable */
import './action'
import './add-channel'
import './add-gradient'
import './add-to-clan-done'
import './add-to-clan'
import './add'
import './ambassador-gradient'
import './arrow-b'
import './arrow-left'
import './arrow-right'
import './arrow-s'
import './arrow-t-b'
import './arrow-t'
import './attach'
import './back'
import './bag'
import './bage'
import './block-in'
import './bracket'
import './calendar'
import './camera'
import './cancel_2'
import './cancel'
import './challenger'
import './channel'
import './charts'
import './chat-2'
import './chat'
import './check-mark'
import './checkbox-off'
import './checkbox-on'
import './checked_1'
import './checked_2'
import './checked'
import './close'
import './comments'
import './controller'
import './cross'
import './danger'
import './dashboard'
import './delete-active'
import './delete'
import './dislike'
import './done'
import './dots'
import './double-elimination'
import './dropdown'
import './edit'
import './editable'
import './enter'
import './error'
import './exclamation'
import './eye_close'
import './eye_open'
import './file'
import './filter'
import './full_logo'
import './gamepad'
import './gamepad2'
import './globe'
import './grand'
import './group-invite'
import './group'
import './hera_shape'
import './info'
import './leaderboards'
import './ligues'
import './like'
import './link'
import './loading'
import './logout'
import './map'
import './master'
import './menu'
import './message-outline'
import './message'
import './more'
import './move-in'
import './move-out'
import './msg'
import './next'
import './not-ready-for-battle'
import './notification'
import './organization-gradient'
import './person_step_icon'
import './person'
import './pick-for-move'
import './picture'
import './platforms'
import './play-2'
import './play-button'
import './play'
import './players'
import './playhera-logo'
import './prize'
import './professional'
import './profile-icon'
import './profile'
import './publish'
import './question_mark'
import './ready-for-battle'
import './reload'
import './round-robin'
import './search-mute'
import './search'
import './send'
import './settings-outline'
import './settings'
import './setup'
import './shape-mask-gradient'
import './shape-mask'
import './shape'
import './share'
import './shield-fill'
import './shield'
import './short_logo'
import './single-elimination'
import './sn-discord'
import './sn-facebook'
import './sn-instagram'
import './sn-telegram'
import './sn-twitch'
import './sn-twitter'
import './sn-youtube'
import './social-discord'
import './social-facebook'
import './social-fb'
import './social-instagram'
import './social-telegram'
import './social-twitch'
import './social-twitter'
import './social-youtube'
import './star'
import './stats'
import './stop'
import './swiss'
import './sword'
import './swords'
import './time'
import './translate'
import './trash-active'
import './trash'
import './update'
import './user'
import './verified'
import './video-placeholder'
import './video'
import './wall'
import './warning'
import './write'
