/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'attach': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.284 2.5c1.164-1.164 2.902-.986 4.002.114 1.1 1.1 1.278 2.838.114 4.002L7.356 13.66a1.79 1.79 0 01-2.526 0 1.79 1.79 0 010-2.525l4.772-4.772a.5.5 0 11.708.707l-4.772 4.772a.79.79 0 000 1.111.79.79 0 001.11 0l7.045-7.044c.71-.711.661-1.813-.114-2.588-.775-.775-1.877-.824-2.588-.114l-7.499 7.5c-.512.512-.79 1.29-.79 2.103 0 .812.278 1.591.79 2.104.51.51 1.31.79 2.151.79.841 0 1.641-.28 2.15-.79l7.5-7.5a.5.5 0 11.707.708l-7.5 7.5c-.74.74-1.819 1.083-2.857 1.083-1.038 0-2.118-.344-2.858-1.084-.737-.737-1.084-1.79-1.084-2.811 0-1.02.347-2.074 1.084-2.811l7.5-7.499z" _fill="#fff"/>'
  }
})
