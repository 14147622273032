/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'file': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M3 2a1 1 0 011-1h5.143a1 1 0 01.633.226L14.633 5.2a1 1 0 01.367.774V16a1 1 0 01-1 1H4a1 1 0 01-1-1V2z" _fill="#D4D4D6"/><path pid="1" d="M9 1h.322a.5.5 0 01.316.113l5.179 4.237a.5.5 0 01.183.387V6h-5a1 1 0 01-1-1V1z" _fill="#898B9B"/>'
  }
})
